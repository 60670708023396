.fs_14{
    font-size:14px;
}
.gap_10{
    gap:10px;
}
.img_logo{
    max-width: 100%;
    width:125px;
}
.img_userlogo{
    width:42px;
}

.btn_custum:focus{
    outline: none !important;
    border:none !important;
    box-shadow:none !important;
}  
.logText{
    text-decoration: none;
    font-size: 14px;
    color: initial;
}
.logText:hover{
    text-decoration: none;
    font-size: 14px;
    color: #2c79fd;
}
.user_infoDiv{
    display:none;
    position: absolute;
    top: 50px;
    left: -20px;
    border-radius:7px;
}
.img_userlogoDiv:hover ~.user_infoDiv{
    display: block;
}

@media only screen and (max-width:600px){
    .img_logo{
        width:100px;
    }
    p{
        font-size:14px;
    }
    .img_userlogo{
        width:32px;
    }
}