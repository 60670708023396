.btn_light{
    background: linear-gradient(-45deg, #37aedd, #3e75b6);
    font-size: 16px;
    font-weight: 500 !important;
    transition:all 5s;
}
.btn_light:hover{
    background: linear-gradient(-45deg, #3e75b6, #37aedd);
}
.form_container{
    width:600px;
    margin: auto;
}
.py_5_cu{
    padding-top:3rem !important;
    padding-bottom:3rem !important;
}
.px_5_cu{
    padding-left:3rem !important;
    padding-right:3rem !important;
}
.loaders{
    position: absolute;
    width: 100%;
    height: 100%;
    background: #dddddd54;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
}
@media only screen and (max-width:768px){
    .form_container{
        width:420px;
    }
}
@media only screen and (max-width:600px){
    .form_container{
        width:100%;
    }
    .py_5_cu{
        padding-top:0.95rem !important;
        padding-bottom:0.95rem !important;
    }
    .px_5_cu{
        padding-left:0.95rem !important;
        padding-right:0.95rem !important;
    }
    .fs-4{
        font-size:18px;
    }
}