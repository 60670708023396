body{
  background-color: #2a2185 !important;
}
.container_cust{
    position: relative;
    width: 100%;
}
.navigation{
    position: fixed;
    width: 300px;
    height: 100%;
    background: #2a2185;
    border-left: 10px solid #2a2185;
    transition: 0.5s;
    overflow: hidden;
    padding-top:50px;
    z-index: 99;
}
.navigation .ulclass{
    position: absolute;
    top: 70px;
    left: 0;
    width: 100%;
}
.small_fs{
    font-size:14px;
}
.navigation ul li {
    position: relative;
    width: 100%;
    list-style: none;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    transition: transform 1s ease;
}
.navigation ul li a{
    color:#fff;
    text-decoration: none;
    display: flex;
    align-items: center;
    padding-left: 24px;
    width:100%;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    transition: transform 1s ease;
    font-weight:600;
    margin-bottom: 5px;
}

.navigation ul li a:hover, .navigation ul li a.active{
    background-color: #fff;
    display: flex;
    align-items: center;
    color: #2a2185;
}

.navigation ul li:hover a, .navigation ul li.active a {
  color: #2a2185;
  text-decoration: none;
  background-color: #fff;
}

.navigation ul li a .icon {
  position: relative;
  display: block;
  min-width: 60px;
  height: 60px;
  line-height: 75px;
  text-align: center;
}
.navigation ul li a .icon ion-icon {
  font-size: 1.75rem;
}

.navigation ul li a .title {
  position: relative;
  display: block;
  padding: 0 20px;
  height: 45px;
  line-height: 42px;
  text-align: start;
  white-space: nowrap;
}

/* --------- curve outside ---------- */

.navigation ul li .active::before {
  content: "";
  position: absolute;
  right: 0;
  top: -50px;
  width: 50px;
  height: 50px;
  background-color: transparent;
  border-radius: 50%;
  box-shadow: 35px 35px 0 10px #fff;
  pointer-events: none;
}

.navigation ul li .active::after {
  content: "";
  position: absolute;
  right: 0;
  bottom: -50px;
  width: 50px;
  height: 50px;
  background-color: transparent;
  border-radius: 50%;
  box-shadow: 35px -35px 0 10px #fff;
  pointer-events: none;
}
.navigation ul li:hover a::before,
.navigation ul li.active a::before {
  content: "";
  position: absolute;
  right: 0;
  top: -50px;
  width: 50px;
  height: 50px;
  background-color: transparent;
  border-radius: 50%;
  box-shadow: 35px 35px 0 10px #fff;
  pointer-events: none;
}
.navigation ul li:hover a::after,
.navigation ul li.active a::after {
  content: "";
  position: absolute;
  right: 0;
  bottom: -50px;
  width: 50px;
  height: 50px;
  background-color: transparent;
  border-radius: 50%;
  box-shadow: 35px -35px 0 10px #fff;
  pointer-events: none;
}
.submenues_dis_n{
    display:none;
}
.submenues_display{
    display: block;
}
.rotate_180{
    transform: rotate(180deg);
}
/* main css */

.main{
    position: absolute;
    width: calc(100% - 300px);
    left: 300px;
    min-height: 100vh;
    background: #fff;
    transition: 0.5s;
    border-top-left-radius: 40px;
    border-bottom-left-radius: 40px;
}
.drop_icon{
  flex: 1;
  margin-right:30px;
}
.d_none_mob{
  display: none;
}
.for_mob{
  display:none;
}
@media only screen and (max-width:768px) {
  .for_mob{
    display:block;
  }
  .for_desk{
    display:none;
  }
  .navigation {
    width: 200px;
  }
  .main{
    /* width: calc(100% - 200px);
    left: 200px; */
    width: calc(100% - 60px);
        left: 200px;
        height: 100vh;
        top: 50px;
  }
  .navigation .ulclass{
    padding-left:7px;
  }
  .submenues_display ul{
    padding-left: 0;
  }
  .navigation ul li a .title{
    padding: 0 12px;
  } 
  
}
.dblock{
  display:none;
}
.for_mob_none{
  display: none;
}

@media only screen and (max-width:600px){
  .main{
    position: static;
    width:100%;
  }
  .for_mob{
    display:none;
  }
  .dblock{
    display:block;
  }
  .for_mob_none{
    display: block;
  }
  body{
    background-color: #fff !important;
  }
  .navigation {
    width: 100%;
  }
}

.new_folder_modal{
  background: white;
  padding: 25px;
  border-radius: 8px;
  text-align: center;
  width: 40%;
  height: 50%;
  margin: auto;
  position: relative;
  top: 41%;
  left: 5%;
  transform: translateY(-50%);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}

.new_folder_modal h2 {
  font-size: 25px;
  margin-bottom: 20px;
}

.folder_input_container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
}

.folder_icon {
  width: 36px !important;
  height: 36px !important;
  color: #2ca4df; 
}

.folder_input {
  flex: 1;
  padding: 8px;
  border: 2px solid #ccc;
  border-radius: 6px;
  font-size: 14px;
  outline: none;
}

.folder_input:focus {
  border-color: #2ca4df;
}

.save_folder_btn {
  background-color: #2ca4df;
  color: white;
  border: none;
  padding: 10px 15px;
  font-size: 16px;
  border-radius: 6px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.save_folder_btn:hover {
  background-color: #1b82b3;
}

.scrollable-folder-list {
  max-height: 300px;
  overflow-y: auto;
  padding-right: 2px;
}

.scrollable-folder-list::-webkit-scrollbar {
  width: 6px;
}

.scrollable-folder-list::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 3px;
}

.scrollable-folder-list::-webkit-scrollbar-track {
  background-color: transparent;
}