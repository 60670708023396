.main_conatiner{
    background-image: url('../../assets/loginbackgroundimg.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 100vh;
}
.cust_card{
    width: 405px;
    margin: auto;
    border-radius: 20px;
}
.card_text_head{
    font-weight: 700;
    font-size:28px;
    color:#383838;
}
.btn_light{
    background: linear-gradient(-45deg, #37aedd, #3e75b6);
    font-size: 16px;
    font-weight: 500 !important;
    transition: .5s ease-in-out;
}
.btn_light:hover{
    background: linear-gradient(-45deg, #3e75b6, #37aedd);
    transform: scale(1.02);
}
.cust_p100{
    padding-top:100px;
}
/* .p5_cust{
    padding:0 150px;
} */
.overflow_hidden{
    overflow: hidden;
}
.wid_100{
    width:100%;
}
.font_12{
    font-size: 12px;
}
@media only screen and (max-width:900px){
    .d_none_mobile{
        display: none;
    }
}
.d_none{
    display:none;
}
.res_otp{
    text-align: center;
    text-decoration: none;
    color: #3b8ec7;
}
@media only screen and (max-width:768px){
    .p5_cust{
        padding:0 20px;
    }
    .col_12{
        width:100% !important;
    }
    .main_conatiner{
        background-size: cover;
    }
}
@media only screen and (max-width:600px) {
    .cust_card{
        width: auto;
        margin: 20px;
        border-radius: 20px;
        padding: 20px;
    }
    .cust_p100{
        padding-top:50px;
    }
    .d_none_mobile{
        display: none;
    }
    .card_text_head{
        font-size:22px;
    }
    .p5_cust{
        padding:0 15px;
    }
    .main_conatiner{
        background-size: cover;
        background-attachment: fixed;
    }
    .overflow_hidden{
        overflow-y: auto;
    }
    .logo_imgwd{
        width:150px;
    }
}