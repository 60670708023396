.cut_btn_st{
    border-top-left-radius:0 !important;
    border-bottom-left-radius: 0 !important;
}
.px_5{
    padding-right: 3rem!important;
    padding-left: 3rem!important;
}
@media only screen and (max-width:768px){
    .px_5{
        padding-right: 0.5rem!important;
        padding-left: 0.5rem!important;
    }
}
@media only screen and (max-width:600px){
    .mt_sm-2{
        margin-top:20px !important;
    }
}