body{
    height: 100Vh;
    background-color: #fff !important;
}

.page_div{
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.page_nav{
    height: 90px;
    width: 100vw;
    padding: 20px;
}

.page_nav .org_logo{
    object-fit: cover;
    margin-left: 10px;
    height: 100%;
}

.page_body{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}

.page_body .config_msg_logo{
    height: 400px;
    width: 500px;
}

.page_body .config_msg_logo img{
    height: 100%;
    width: 100%;
}


.gDrive{
    letter-spacing: 2px;
    margin-bottom: 0;
}

.config_msg_inner{
    letter-spacing: 3px;
}

.config_msg_inner .success_msg{
    color: #36AA58;
    margin-left: 10px;
}

.config_msg_inner .failed_msg{
    color: #EA493D;
    margin-left: 10px;
}

