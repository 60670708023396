
.search_input{
    width: 65%;
    border: none;
    border-radius: 8px;
    border-color: #ddd;
    outline: 0;
    padding: 10px;
    padding-right:3.2rem;
    resize: none;
    height:50px;   
    overflow-y: auto;
    max-height: calc(1.5em * 5);
    
}
.search_conatiner{
    display: flex;
    justify-content: center;
    position: Fixed;
    bottom: 50px;
    width: -webkit-fill-available;
    width: -moz-available;
}
.search_send_icon{
   
    transform: rotate(54deg);
    color: #ccc;
    position: absolute;
    right: 18px;
    bottom: 18px;
}
.btn_c{
    position: relative;
    border: none;
}
.searchResult{
    width: 65%;
    margin: auto;
}
.icon_box{
    color: #fff;
    width: 65px;
    height: 28px;
    text-align: center;
    border-radius: 4px;
}
.icon_box_bl{
    background: #2a2185;
}
.icon_box_oran{
    background: #e96224;
}
p{
    color:#383838;
}
.doc_area{
    width: fit-content;
    padding: 10px;
    border-radius: 6px;
    align-items: center;
    box-shadow: 0px 0px 4px #ddd;
}
.gap_10{
    gap:10px;
}
.react_icons .icon_hover{
    box-shadow: -3px -2px 9px -4px #2a218594;
    border-radius: 50%;
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: #b8bcc0;
    font-size: 14px;
    transition: transform 1s ease;
}
.react_icons .icon_hover:hover{
    color:#2a2185;
    box-shadow: 0px 1px 4px #ccc;
    transform: scale(1.5);
    border: none;
}

.result_container{
    height:65vh;
    overflow-y: auto;
    transition: all 0.3s ease-in;
}
.source_bard{
    display: flex;
    align-items: center;
    gap: 6px;
    text-decoration: none;
    box-shadow: -3px -2px 9px -4px #2a218594;
    width: fit-content;
    padding: 4px 18px;
    border-radius: 35px;
    font-size: 14px;
    transition: transform 1s ease;
}
.source_bard:hover{
    box-shadow: 0px 1px 4px #ccc;
    transform: scale(1.1);
}
.source_bard .sourceText, .source_bard .source_icon{
    margin: 0;
    font-weight: 700;
    color: #b8bcc0;
    border:none;
    transition: transform 1s ease;
}
.source_bard:hover .sourceText, .source_bard:hover .source_icon
{
    color:#2a2185;
}
textarea::-webkit-scrollbar {
    height: 1rem;
    width: 0.5rem;
}
textarea::-webkit-scrollbar-thumb{
    --tw-border-opacity: 1;
    background-color: rgba(217,217,227,.8);
    border-color: rgba(255,255,255,var(--tw-border-opacity));
    border-radius: 9999px;
    border-width: 1px;
}
.listRespo::marker{
    color:#b8bcc0;
}

@media only screen and (max-width:768px){
    .searchResult{
        width: 100%;
    }
    .search_conatiner {
        justify-content: flex-start;
    }
    .search_input {
        width: 95%;
    }    
    .flex_direc{
        flex-direction: column;
    }
    .result_container{
        height:60vh;
    }
    .icon_box{
        width: 65px;
        height: 22px;
        font-size:13px;
    }
}
