.btn_light{
    background: linear-gradient(-45deg, #37aedd, #3e75b6);
    font-size: 16px;
    font-weight: 500 !important;
    transition:all 5s;
}
.btn_light:hover{
    background: linear-gradient(-45deg, #3e75b6, #37aedd);
}
.form_container{
    width: auto;
    margin: auto;
}
.py_5_cu{
    padding-top:3rem !important;
    padding-bottom:3rem !important;
}
.px_5_cu{
    padding-left:3rem !important;
    padding-right:3rem !important;
}
.loaders{
    position: absolute;
    width: 100%;
    height: 100%;
    background: #dddddd54;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
}

.visiblePassword{
    position: absolute;
    right: -1px;
    top: 13%;
    transform: translate(-50%);
    cursor: pointer;
}

@media only screen and (max-width:768px){
    .form_container{
        width:420px;
    }
}
@media only screen and (max-width:600px){
    .form_container{
        width:100%;
    }
    .py_5_cu{
        padding-top:0.95rem !important;
        padding-bottom:0.95rem !important;
    }
    .px_5_cu{
        padding-left:0.95rem !important;
        padding-right:0.95rem !important;
    }
    .fs-4{
        font-size:18px;
    }
}

.gDrive-btn{
    color: #302e2e !important;
    border: 1px solid  #3e75b6 !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    gap: 5px !important;
    width: 25rem;
}

.gDrive-btn:hover{
    background-color: #3367D6;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.gDrive-icon{
    color: #34A853;
}

.page_back{
    display: flex;
    justify-content: center;
    align-items: center;
}

.back_msg{
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    gap: 2px !important;
    width: 25%;
    letter-spacing: 1px !important;
    font-size: 1.2rem !important;
    padding: 5px;
    border-radius: 5px;
}

.gpt_form{
    display: flex !important;
    gap: 0px !important;
    align-items: center !important;
    padding: 0px !important;
    height: 40px;
    overflow: hidden !important;
}

.gpt_input{
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    border: none;
    width: 95%;
    height: 100% !important;
    padding: 3px 8px !important;
    box-sizing: border-box !important;
}

.gpt_input:focus {
    outline: none !important; 
}

.edit-class{
    width: 10% !important; 
    height: 100% !important; 
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #3E78B8; 
    color: white; 
    cursor: pointer;
    padding: 8px;
}

.edit-ico{
   padding: 0;
   
}

.select_model{
    width: 100%;
    padding: 10px 14px;
    border: 1px solid #ddd;
    border-radius: 6px;
    font-size: 14px;
    background-color: #fff;
    cursor: pointer;
    transition: border-color 0.3s;
    outline: none;
}

.select_model:focus {
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.2);
}

.select_model option:hover {
    background: #007bff;
    color: white;
}

.select_model option:disabled {
    font-weight: 500;
    color: #aaa;
}

.select_model option {
    background: white;
    color: #333;
    padding: 10px;
    font-size: 14px;
}

.edit-class:hover{
    background-color: #38ADDC;
}

.user_forms{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    background: #f8f9fa;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.user_forms_fn, .user_utils_btn{
    display: flex;
    align-items: center;
    padding: 12px;
}

.user_forms_fn{
    gap: 10px;
}

.user_utils_btn{
    gap: 5px;
}

.user_forms_input{
    padding: 10px 14px;
    border: 1px solid #ddd;
    border-radius: 6px;
    font-size: 14px;
    outline: none;
    transition: border-color 0.3s;
    background: #fff;
    width: 180px;
}

.user_forms_input:focus{
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.2);
}


.user_forms_input option:hover {
    background: #007bff;
    color: white;
}

.user_forms_input option:disabled {
    font-weight: 500;
    color: #aaa;
}

.user_forms_input option {
    background: white;
    color: #333;
    padding: 10px;
    font-size: 14px;
}

.permission_btn, .add_btn, .delete_btn{
    padding: 10px 16px;
    border: none;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    transition: background 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    color: white;
}

.permission_btn{
    background-color:#38A9DA;
}

.permission_btn:hover{
    background-color: #3a529d;
}

.add_btn{
    background-color: #38A9DA;
    color: white;
}

.add_btn:hover{
    background-color: #0056b3;
}

.delete_btn{
    background-color: #dc3545;
    color: white;
}

.delete_btn:hover{
    background-color: #c82333;
}

.plus-btn{
    background-color: #4b6cb7;
    border: none;
    color: white;
    font-size: 16px;
    font-weight: bold;
    width: 40px;
    height: 40px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

.plus-btn:hover {
    background-color: #3a529d;
    transform: scale(1.1);
}

.modal_inner{
    position: absolute;
    top: 50%;
    left: 55%;
    transform: translate(-50%, -50%);
    width: 50%;
    height: 80%;
    background-color: #fff;
    box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.2);
    padding: 24px;
    border-radius: 16px;
}

.modal_center::-webkit-scrollbar{
    width: 8px;
}

.modal_center::-webkit-scrollbar-thumb {
    background: #9C9C9C; 
    border-radius: 8px;
}

.modal_center::-webkit-scrollbar-track {
    background: #6c6b6b; 
    border-radius: 5px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
}

.modal_header{
    display: flex;
    justify-content: space-between;
    font-size: 1.2rem;
    font-weight: 500;
    padding-bottom: 10px;
    border-bottom: 2px solid #9C9C9C;
    height: 10%;
}

.modal_center{
    height: 79% !important;
    overflow: auto !important;
}

.modal_actions{
    display: flex;
    justify-content: center;
    gap: 20px;
    border-top: 2px solid #9C9C9C;
    font-size: 1.2rem;
    font-weight: 500;
    padding-top: 10px;
    height: 10%;
}

.all_check_btn{
    text-transform: none !important;
}

.permissionBtn_table{
    text-align: center !important;
    cursor: pointer;
    transition: color 0.2s;
    color: #af52bf !important;
} 


.permissionBtn_table:hover{
    color: #9c27b0 !important;
}

