.font_62{
    font-size:62px;
}
.font_20{
    font-size:20px;
}
.wid_fitCont{
    width:fit-content;
}
.gap_20{
    gap:20px;
}
.cust_table{
    table-layout: auto;
    border-collapse: collapse;
    width:100%;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
}
.cust_table tr th{
    padding: 0.7em 1em;
    background:#DDDEEA;
    font-size:15px; 
    font-weight: 500;
}
.cust_table tr td{
    padding: 0.7em 1em;
    font-size:14px;
}
.cust_table tr:first-child th:first-child {
    border-top-left-radius: 15px;
}
  
.cust_table tr:first-child th:last-child {
border-top-right-radius: 15px;
}
.cust_table tr:last-child td:first-child {
border-bottom-left-radius: 15px;
}
.cust_table tr:last-child td:last-child {
border-bottom-right-radius: 15px;
}
.cust_table tr:nth-child(odd){
background: #F6F7FB;
}
.cl_bl{
    color: #2c79fd;
    cursor: pointer;
}
@media only screen and (max-width:768px){
    .font_62{
        font-size:40px;
    }
    .font_20{
        font-size:14px;
    }
    .overflow_auto{
        overflow: auto;
    }
}
@media only screen and (max-width:600px){
    .overflow_auto{
        overflow: auto;
    }
}


